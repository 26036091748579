<template>
    <div>

        <CookieConsent :message="message" :buttonLabel="btnLabel" :cookieName="cookieId" :linkLabel="linkLabel"
            :href="href"></CookieConsent>

    </div>
</template>
<script>
    export default {
        data: function () {
            return {
                message: '„Diese Website verwendet Cookies – nähere Informationen dazu und zu Ihren Rechten als Benutzer finden Sie in unserer Datenschutzerklärung.“',
                btnLabel: 'Alles klar.',
                linkLabel: 'Mehr dazu',
                cookieId: 'cookieconsent_state',
                href: 'https://swissdominoeffect.com/datenschutz#cookies'
            }
        }
    }
</script>