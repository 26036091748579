<template>
  <div id="app">
    <Navigation v-if="!isCustomNavFooter" />
    <router-view/>
    <Footer v-if="!isCustomNavFooter" />
    <CookieConsent />
  </div>
</template>

<style lang="sass">
.hero
  &.has-background
    position: relative
    overflow: hidden
  &-background
    position: absolute
    object-fit: cover
    object-position: center center
    width: 100%
    height: 100%
    &.is-transparent
      opacity: 0.7
</style>
<style>
    .block p{
        line-height: 180%;
        text-align: justify;
    }
</style>
<script>
  import Navigation from '@/components/Navigation'
  import Footer from '@/components/Footer'
  import CookieConsent from '@/components/CookieConsent'
  export default {
    metaInfo: {
      meta: [{
          charset: 'utf-8'
        },
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1'
        },
        {
          name: 'description',
          content: 'Alles über Kettenreaktionen mit Dominosteinen.'
        }
      ],
      titleTemplate: '%s - Swiss Domino Effect GmbH'
    },
    computed: {
      isCustomNavFooter() {
        return this.$route.name == 'portal' || this.$route.name == 'pickup'
      }
    },
    components: {
      Navigation,
      Footer,
      CookieConsent
    }
  }
</script>