import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Buefy from 'buefy'
import VueMobileDetection from 'vue-mobile-detection'
import VueMeta from 'vue-meta'
import Address from '@/components/Address'
import './assets/style.scss'
import VueAnalytics from 'vue-analytics'
import VueCookies from 'vue-cookies'
import cookieconsent from 'vue-cookieconsent-component'

Vue.config.productionTip = false

Vue.use(Buefy)
Vue.use(VueMobileDetection)
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})
Vue.use(VueAnalytics, {
  id: 'UA-192707090-1',
  router
})
Vue.use(VueCookies)
//Vue.$cookies.config('30d', '', '', true)

Vue.component('Address', Address)
Vue.component('CookieConsent', cookieconsent)

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
